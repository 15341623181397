import CardDetails from './CardDetails';
import BankDetails from './BankDetails';
import OnboardingTaskList from '../../../shared/OnboardingTaskList';

const SettingsPayments = () => {
  return (
    <div>
      <OnboardingTaskList />

      <CardDetails />
      <br />
      <BankDetails />
    </div>
  );
};

export default SettingsPayments;
