import {useContext, useEffect, useState} from 'react';
import {Constants} from '../utils/constants';
import {NavLink} from 'react-router-dom';
import AlertComponent from './AlertComponent';
import {AuthContext} from '../contexts/useAuthContext';
import {BusinessContext} from '../dashboard/settings/business-profile/useBusinessContext';

const OnboardingTaskList = () => {
  const [loading, setLoading] = useState(false);
  const [onboardingTasksCompleted, setOnboardingTasksCompleted] = useState(false);

  const { state: authState, isAdmin } = useContext(AuthContext);
  const { getBusiness } = useContext(BusinessContext);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const business = await getBusiness();

        if (Object.keys(business).length === 0) {
          setLoading(false);
          return;
        }

        const businessAttributes = business.attributes;

        setOnboardingTasksCompleted(businessAttributes.onboarding_tasks_completed);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [authState.currentBusiness]);

  const canShow = !onboardingTasksCompleted && isAdmin() && !loading;

  return (
    canShow && <AlertComponent
      type="info"
      text={<span>
        Click <NavLink to={Constants.Links.Sidebar.Path.Home} className="alert__link">here</NavLink> to go back to your onboarding task list
      </span>}
    />
  );
};

export default OnboardingTaskList;
